import { endpoints } from "../config";
import {
  HostedFieldClasses,
  HostedFieldState,
  BraintreeErrorFieldsInvalid,
  InvalidMessages,
  FieldKeys,
} from "types/payment";

const defaultFieldClasses: HostedFieldClasses = {
  moved: null,
  notEmpty: null,
  isActive: null,
  fieldValid: null,
  fieldInvalid: null,
  invalidMessage: null,
};

export const paymentFieldState: HostedFieldState = {
  cardNumber: { ...defaultFieldClasses },
  cvv: { ...defaultFieldClasses },
  expirationDate: { ...defaultFieldClasses },
};

export const paymentForm = {
  title: "Payment Method",
  securityNote:
    "All transactions are secured by 256-bit bank-grade encryption.",
  labels: {
    creditCard: "Card Number",
    cvv: "CVV",
    expirationDate: "Expiration Date",
    firstName: "First Name",
    lastName: "Last Name",
    card: "Credit Card",
    paypal: "Paypal",
    visaCheckout: "Visa Checkout",
    applePay: "Apple Pay",
    masterpass: "Masterpass",
    billing: "Billing Information",
    isDefault: "Make This My Default Payment Method",
    saveCardForLater: "Securely Save My Card for Later",
    isBillingEqualShipping: "Billing Address Is Same as Shipping Address",
    isBillingEqualDefaultShipping:
      "Billing Address Is Same as Default Shipping Address",
  },
};

export enum PAYMENT_TYPES {
  CREDIT_CARD = "CREDIT_CARD",
  PAYPAL = "PAYPAL",
  ALLOCATED_CREDIT = "ALLOCATED_CREDIT",
  INVOICE = "INVOICE",
  APPLE_PAY = "APPLE_PAY",
}

export const paymentMethods = {
  ALLOCATION: "ALLOCATION",
  APPLE_PAY: "APPLE_PAY",
  CREDIT_CARD: "CREDIT_CARD",
  INVOICE: "INVOICE",
  MASTERPASS: "MASTERPASS",
  PAYPAL: "PAYPAL",
  VISA_CHECKOUT: "VISA_CHECKOUT",
};

export const paymentMethodMap: Record<string, PAYMENT_TYPES> = {
  ALLOCATED_CREDIT: PAYMENT_TYPES.ALLOCATED_CREDIT,
  CREDIT_CARD: PAYMENT_TYPES.CREDIT_CARD,
  PAYPAL: PAYMENT_TYPES.PAYPAL,
  INVOICE: PAYMENT_TYPES.INVOICE,
};

export const paymentImgPaths = {
  americanExpress: `${endpoints.cdn}/static-images/americanexpress.png`,
  applePay: `${endpoints.cdn}/static-images/applepaylogoofficial.png`,
  creditCardImg: `${endpoints.cdn}/static-images/creditcardlogo.png`,
  discover: `${endpoints.cdn}/static-images/discoverlogo.png`,
  masterCardImg: `${endpoints.cdn}/static-images/mastercard.png`,
  masterpass: `${endpoints.cdn}/static-images/masterpass.svg`,
  paypal: `${endpoints.cdn}/static-images/paypallogo.png`,
  visa: `${endpoints.cdn}/static-images/visalogo.png`,
  visaCheckout: `${endpoints.cdn}/static-images/visaCheckout.png`,
  allocation: `${endpoints.cdn}/static-images/allocation.svg`,
};

export const paymentInvalidMessages: InvalidMessages = {
  cardNumber: "Please enter a valid card number",
  expirationDate: "Please enter a valid expiration date",
  cvv: "Please enter a valid security code",
};

export const braintreeErrors = (
  tokenizeErr: BraintreeErrorFieldsInvalid
): string => {
  const { creditCard, cvv, expirationDate } = paymentForm.labels;
  const fieldKeyMap: FieldKeys = {
    number: creditCard,
    cvv,
    expirationDate,
  };

  const parseInvalidFieldKeys = (keys: string[]) => {
    return keys.map((key) => fieldKeyMap[key]).join(", ");
  };

  // https://braintree.github.io/braintree-web/3.67.0/HostedFields.html#tokenize-examples
  switch (tokenizeErr.code) {
    case "HOSTED_FIELDS_FIELDS_EMPTY":
      return "All payment fields are empty! Please enter your payment.";
    case "HOSTED_FIELDS_FIELDS_INVALID":
      return `Some payment fields are invalid: ${parseInvalidFieldKeys(
        tokenizeErr.details.invalidFieldKeys
      )}.`;
    case "HOSTED_FIELDS_TOKENIZATION_CVV_VERIFICATION_FAILED":
      return "Invalid CVV, please try again.";
    case "HOSTED_FIELDS_FAILED_TOKENIZATION":
      return "Unfortunately we were unable to process your payment. Please try again with another payment method.";
    case "HOSTED_FIELDS_TOKENIZATION_NETWORK_ERROR":
      return "There was an error processing your payment. Please try again.";
    default:
      return `Something bad happened! ${tokenizeErr}`;
  }
};

export enum RegulatoryCodes {
  NONE = 0,
  SALES_TAX = 1,
  SWEETENED_TAX = 35,
  CONTAINER_FEE = 61,
}
